
.DividerHeading_dividerHeading__Rfk9W {
	display: grid;
	grid:
        '....... ......... ........ ........... ....... .......... .....' 1em
        '....... line-left ........ text        ....... line-right .....'
        '....... ......... ........ ........... ....... .......... .....' 1em
        / .625em    1fr       1em     auto        1em     1fr        .625em
		;
	font-size: 1.17em;
}

.DividerHeading_dividerHeading__Rfk9W > * {
	grid-area: text;
}

.DividerHeading_dividerHeading__Rfk9W::before, .DividerHeading_dividerHeading__Rfk9W::after {
    border-top: 1px solid currentColor;
    content: "";
    height: 0;
    margin: auto;
    width: 100%;
}

.DividerHeading_dividerHeading__Rfk9W::before {
    grid-area: line-left;
}

.DividerHeading_dividerHeading__Rfk9W::after {
    grid-area: line-right;
}



.style_heroVideo__WaAyd {
    object-fit: cover;
}

.Link_styleless__JKyIB {
  text-decoration: none;
  color: inherit;
}

.link_link__SHFYR {
    font-style: italic;
    color: currentColor;
}

.link_link__SHFYR:visited {
	-webkit-text-decoration-color: var(--visited-link-color);
	        text-decoration-color: var(--visited-link-color);
}

.link_link__SHFYR:link {
	-webkit-text-decoration-color: var(--link-color);
	        text-decoration-color: var(--link-color);
}

.TimeEye_time-eye__2_UvF * {
  stroke: var(--foreground-color, black);
  fill: none;
}

.TimeEye_time-eye__2_UvF [data-part="pupil"] {
  fill: var(--foreground-color, black);
}

.TimeEye_time-eye__2_UvF [data-part="tear"] {
  fill: var(--background-color, white);
}

.ZemnmezLogo_zemnmezLogo__XPd38 * {
  fill: var(--foreground-color, black);
}

.style_main__17tAE {
    display: grid;
}

.style_banner__HG7oP, .style_headerBgv__YrzfV {
    grid-area: banner;
	width: 100%;
	height: 100%;
}

.style_banner__HG7oP { display: grid }

.style_letterHead__ktfQN {
	padding: 2em;
	text-align: center;
	background-color: var(--background-color);
	margin: auto;
	display: grid;
	grid:
	    "    logo      " 2em
		"    handle    " auto
		"    fullName  " auto / auto;
}

.style_letterHead__ktfQN {
	font-size: large;
}

.style_letterHead__ktfQN > .style_handle__gmzfz {
	grid-area: handle;
}

.style_letterHead__ktfQN > .style_fullName__jH49f {
	grid-area: fullName;
}

.style_letterHead__ktfQN > .style_logo__Pkkjj {
	width: 100%;
	height: 100%;
	grid-area: logo;
}

.style_banner__HG7oP h1 {
	background-color: white;
	margin: auto;
}

.style_content__AQeNT {
    grid-area: content;
	padding-top: 4em;
}

.style_headerBgv__YrzfV {
    z-index: -1;
}

@media (orientation: landscape) {
    .style_main__17tAE {
        grid: " banner content " 100vh
              " xxxxxx content " auto
			  " xxxxxx footer  " auto
              / 1fr    1fr           ;
    }

    /*
       in this case, the banner should have a fixed
       position, otherwise we'll scroll past it and the layout
       will look weird.
    */

    .style_headerBgv__YrzfV, .style_banner__HG7oP { position: -webkit-sticky; position: sticky; top: 0 }
}

@media (orientation: portrait) {
    .style_main__17tAE {
        grid: "banner" 100vh
              "content" auto
              "footer" auto
              /1fr ;
    }
}


.style_footer__lYx8_ {
	grid-area: footer;
	display: grid;
	grid:
	    "title   title       title   title        title"
		"....... left-spacer future  right-spacer ......." 6rem
		"tagline tagline     tagline tagline      tagline"
		"....... .......     ....... .......      ......." 1em
		"copy 	 copy        copy    copy         copy"
		"....... .......     ....... .......       ......." 4rem
		/1fr     2rem        3rem    2rem          1fr
}

.style_copyright__iibg0 {
	grid-area: copy;
}

.style_footer__lYx8_ > h2 {
	grid-area: title;
}

.style_future__8O3mP {
	grid-area: future;
	width: 100%;
	height: 100%
}

.style_footer__lYx8_:before {
    content: "\2013";
    grid-area: left-spacer;
    margin: auto;
}

.style_tagline__7NcDE {
	grid-area: tagline;
}

.style_tagline__7NcDE, .style_copyright__iibg0 {
	max-width: 15em;
	margin: auto;
	text-align: center;
}

.style_footer__lYx8_:after {
    content: "\2013";
    grid-area: right-spacer;
    margin: auto;
}


.style_logoInline__UM2GX {
	display: inline-block;
	height: 1em;
	width: auto;
	vertical-align: middle;
}

.style_links__ud_9K {
	margin-top: 2em;
	display: flex;
}

.style_links__ud_9K > * {
	margin: auto;
}

html, body, :root {
    margin: 0;
    padding: 0;
}


:root {
	/*
	Notify the browser that the default colour scheme is light.
	https://developer.mozilla.org/en-US/docs/Web/CSS/color-scheme
	*/
	color-scheme: light;
	--background-color: white;
	--foreground-color: black;
	/*
	a pretty kind of vermillion-pink, sampled from the
	hero video
	*/
	--visited-link-color: #ff9389;
	/*
	a pretty kind of light green, also sampled from the
	hero video
	*/
	--link-color: #98cb0c;
}


/*
	Dark mode colours have been generated
	by sampling light mode colours and
	adjusting their lightness in the CIELAB
	colour space.

	See: https://colorizer.org/
*/
@media (prefers-color-scheme: dark) {
	:root {
		/*
		Notify the browser that the default colour scheme is now dark.
		https://developer.mozilla.org/en-US/docs/Web/CSS/color-scheme
		*/
		color-scheme: dark;
		--background-color: #010;
		--foreground-color: #96a497;
		--visited-link-color: #ad554b;
		--link-color: #5b880a;
	}
}

:root {
	color: var(--foreground-color);
	background: var(--background-color);
}

h1, h2, h3, h4, h5 { font-weight: normal }

input, button {
	font: inherit;
	field-sizing: content;
}

input[type="text"] {
	display: inline;
	padding: 0;
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	border: none;
	border-bottom: 1px dotted var(--foreground-color);
}

svg circle {
	stroke: var(--foreground-color)
}


hr {
	width: 100%;
	border: 0;
	border-top: 1px solid currentColor;
}

/* cyrillic-ext */
@font-face {
  font-family: '__Lora_f1f2c1';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/194e68b1ff4d40ee-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Lora_f1f2c1';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/118bf6d367285d36-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* math */
@font-face {
  font-family: '__Lora_f1f2c1';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/b94157c21e18e701-s.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0310, U+0312, U+0315, U+031A, U+0326-0327, U+032C, U+032F-0330, U+0332-0333, U+0338, U+033A, U+0346, U+034D, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2016-2017, U+2034-2038, U+203C, U+2040, U+2043, U+2047, U+2050, U+2057, U+205F, U+2070-2071, U+2074-208E, U+2090-209C, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2100-2112, U+2114-2115, U+2117-2121, U+2123-214F, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B7, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+3030, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}
/* symbols */
@font-face {
  font-family: '__Lora_f1f2c1';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/ec4a340d7d92ca39-s.woff2) format('woff2');
  unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8BB, U+1F8C0-1F8C1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA89, U+1FA8F-1FAC6, U+1FACE-1FADC, U+1FADF-1FAE9, U+1FAF0-1FAF8, U+1FB00-1FBFF;
}
/* vietnamese */
@font-face {
  font-family: '__Lora_f1f2c1';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/47124bda5734331b-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Lora_f1f2c1';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/aefa312f0ff0f860-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Lora_f1f2c1';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/e9ed9197b1d41e00-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Lora_f1f2c1';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/194e68b1ff4d40ee-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Lora_f1f2c1';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/118bf6d367285d36-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* math */
@font-face {
  font-family: '__Lora_f1f2c1';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/b94157c21e18e701-s.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0310, U+0312, U+0315, U+031A, U+0326-0327, U+032C, U+032F-0330, U+0332-0333, U+0338, U+033A, U+0346, U+034D, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2016-2017, U+2034-2038, U+203C, U+2040, U+2043, U+2047, U+2050, U+2057, U+205F, U+2070-2071, U+2074-208E, U+2090-209C, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2100-2112, U+2114-2115, U+2117-2121, U+2123-214F, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B7, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+3030, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}
/* symbols */
@font-face {
  font-family: '__Lora_f1f2c1';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/ec4a340d7d92ca39-s.woff2) format('woff2');
  unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8BB, U+1F8C0-1F8C1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA89, U+1FA8F-1FAC6, U+1FACE-1FADC, U+1FADF-1FAE9, U+1FAF0-1FAF8, U+1FB00-1FBFF;
}
/* vietnamese */
@font-face {
  font-family: '__Lora_f1f2c1';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/47124bda5734331b-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Lora_f1f2c1';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/aefa312f0ff0f860-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Lora_f1f2c1';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/e9ed9197b1d41e00-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Lora_f1f2c1';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/8976b37f8037a4c0-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Lora_f1f2c1';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/ada9171bd6839183-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* math */
@font-face {
  font-family: '__Lora_f1f2c1';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/21633f890e359fee-s.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0310, U+0312, U+0315, U+031A, U+0326-0327, U+032C, U+032F-0330, U+0332-0333, U+0338, U+033A, U+0346, U+034D, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2016-2017, U+2034-2038, U+203C, U+2040, U+2043, U+2047, U+2050, U+2057, U+205F, U+2070-2071, U+2074-208E, U+2090-209C, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2100-2112, U+2114-2115, U+2117-2121, U+2123-214F, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B7, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+3030, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}
/* symbols */
@font-face {
  font-family: '__Lora_f1f2c1';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/9c526da3fdc4ac93-s.woff2) format('woff2');
  unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8BB, U+1F8C0-1F8C1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA89, U+1FA8F-1FAC6, U+1FACE-1FADC, U+1FADF-1FAE9, U+1FAF0-1FAF8, U+1FB00-1FBFF;
}
/* vietnamese */
@font-face {
  font-family: '__Lora_f1f2c1';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/d364c42434837330-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Lora_f1f2c1';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/293d9d85a89f760b-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Lora_f1f2c1';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/3a8dc5763a8ae4b2-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Lora_f1f2c1';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/8976b37f8037a4c0-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Lora_f1f2c1';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/ada9171bd6839183-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* math */
@font-face {
  font-family: '__Lora_f1f2c1';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/21633f890e359fee-s.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0310, U+0312, U+0315, U+031A, U+0326-0327, U+032C, U+032F-0330, U+0332-0333, U+0338, U+033A, U+0346, U+034D, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2016-2017, U+2034-2038, U+203C, U+2040, U+2043, U+2047, U+2050, U+2057, U+205F, U+2070-2071, U+2074-208E, U+2090-209C, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2100-2112, U+2114-2115, U+2117-2121, U+2123-214F, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B7, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+3030, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}
/* symbols */
@font-face {
  font-family: '__Lora_f1f2c1';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/9c526da3fdc4ac93-s.woff2) format('woff2');
  unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8BB, U+1F8C0-1F8C1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA89, U+1FA8F-1FAC6, U+1FACE-1FADC, U+1FADF-1FAE9, U+1FAF0-1FAF8, U+1FB00-1FBFF;
}
/* vietnamese */
@font-face {
  font-family: '__Lora_f1f2c1';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/d364c42434837330-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Lora_f1f2c1';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/293d9d85a89f760b-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Lora_f1f2c1';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/3a8dc5763a8ae4b2-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Lora_Fallback_f1f2c1';src: local("Times New Roman");ascent-override: 86.33%;descent-override: 23.51%;line-gap-override: 0.00%;size-adjust: 116.53%
}.__className_f1f2c1 {font-family: '__Lora_f1f2c1', '__Lora_Fallback_f1f2c1'
}

