html, body, :root {
    margin: 0;
    padding: 0;
}


:root {
	/*
	Notify the browser that the default colour scheme is light.
	https://developer.mozilla.org/en-US/docs/Web/CSS/color-scheme
	*/
	color-scheme: light;
	--background-color: white;
	--foreground-color: black;
	/*
	a pretty kind of vermillion-pink, sampled from the
	hero video
	*/
	--visited-link-color: #ff9389;
	/*
	a pretty kind of light green, also sampled from the
	hero video
	*/
	--link-color: #98cb0c;
}


/*
	Dark mode colours have been generated
	by sampling light mode colours and
	adjusting their lightness in the CIELAB
	colour space.

	See: https://colorizer.org/
*/
@media (prefers-color-scheme: dark) {
	:root {
		/*
		Notify the browser that the default colour scheme is now dark.
		https://developer.mozilla.org/en-US/docs/Web/CSS/color-scheme
		*/
		color-scheme: dark;
		--background-color: #010;
		--foreground-color: #96a497;
		--visited-link-color: #ad554b;
		--link-color: #5b880a;
	}
}

:root {
	color: var(--foreground-color);
	background: var(--background-color);
}

h1, h2, h3, h4, h5 { font-weight: normal }

input, button {
	font: inherit;
	field-sizing: content;
}

input[type="text"] {
	display: inline;
	padding: 0;
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	border: none;
	border-bottom: 1px dotted var(--foreground-color);
}

svg circle {
	stroke: var(--foreground-color)
}


hr {
	width: 100%;
	border: 0;
	border-top: 1px solid currentColor;
}
