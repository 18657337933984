.main {
    display: grid;
}

.banner, .headerBgv {
    grid-area: banner;
	width: 100%;
	height: 100%;
}

.banner { display: grid }

.letterHead {
	padding: 2em;
	text-align: center;
	background-color: var(--background-color);
	margin: auto;
	display: grid;
	grid:
	    "    logo      " 2em
		"    handle    " auto
		"    fullName  " auto / auto;
}

.letterHead {
	font-size: large;
}

.letterHead > .handle {
	grid-area: handle;
}

.letterHead > .fullName {
	grid-area: fullName;
}

.letterHead > .logo {
	width: 100%;
	height: 100%;
	grid-area: logo;
}

.banner h1 {
	background-color: white;
	margin: auto;
}

.content {
    grid-area: content;
	padding-top: 4em;
}

.headerBgv {
    z-index: -1;
}

@media (orientation: landscape) {
    .main {
        grid: " banner content " 100vh
              " xxxxxx content " auto
			  " xxxxxx footer  " auto
              / 1fr    1fr           ;
    }

    /*
       in this case, the banner should have a fixed
       position, otherwise we'll scroll past it and the layout
       will look weird.
    */

    .headerBgv, .banner { position: sticky; top: 0 }
}

@media (orientation: portrait) {
    .main {
        grid: "banner" 100vh
              "content" auto
              "footer" auto
              /1fr ;
    }
}


.footer {
	grid-area: footer;
	display: grid;
	grid:
	    "title   title       title   title        title"
		"....... left-spacer future  right-spacer ......." 6rem
		"tagline tagline     tagline tagline      tagline"
		"....... .......     ....... .......      ......." 1em
		"copy 	 copy        copy    copy         copy"
		"....... .......     ....... .......       ......." 4rem
		/1fr     2rem        3rem    2rem          1fr
}

.copyright {
	grid-area: copy;
}

.footer > h2 {
	grid-area: title;
}

.future {
	grid-area: future;
	width: 100%;
	height: 100%
}

.footer:before {
    content: "\2013";
    grid-area: left-spacer;
    margin: auto;
}

.tagline {
	grid-area: tagline;
}

.tagline, .copyright {
	max-width: 15em;
	margin: auto;
	text-align: center;
}

.footer:after {
    content: "\2013";
    grid-area: right-spacer;
    margin: auto;
}


.logoInline {
	display: inline-block;
	height: 1em;
	width: auto;
	vertical-align: middle;
}

.links {
	margin-top: 2em;
	display: flex;
}

.links > * {
	margin: auto;
}
